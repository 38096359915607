import React from "react"
import styled from "styled-components"

const Construction = styled("p")``

const Index = ({ className }) => {
  return (
    <section className={className}>
      <h2>WIP</h2>
      <Construction>🚧</Construction>
    </section>
  )
}

export default styled(Index)`
  ${Construction} {
    font-size: 120px;
    text-align: center;
    width: 100%;
    position: absolute;
    top: 40%;
  }

  h2 {
    text-align: center;
    position: absolute;
    top: 30%;
    width: 100%;
  }
`
